import React from 'react';

import {
  Box,
  Heading,
  Image,
  VStack,
  Circle,
  Text,
  HStack,
} from '@chakra-ui/react';

// Imports for Google Storage files
import { useStorageDownloadUrl } from '../hooks/useStorageDownloadUrl';

export const CompanyCard = (props) => {
  // console.log(props);

  const imageUrl = useStorageDownloadUrl(props.company.thumbUrl,
    props.company.companyImageName
      ? props.company.companyImageName
      : props.company.imageName
  );

  return (
    <Box
      bg="white"
      p={'5px'}
      shadow="md"
      borderWidth="1px"
      w={{ base: '10rem', md: '12rem' }}
      // m={2}s
      justifyContent="center"
      rounded={10}
      overflow="hidden"
      h={{ base: '100%', md: '100%' }}
      // transition=" 0.5s"
      _hover={{ bg: 'white', cursor: 'pointer' }}
    >
      {(props.score || props.score === 0) &&

        <HStack justify={'end'}>
          <Circle
            bg={'#00ABB6'}
            color={'white'}
            size={'48px'}
          >
            <Text fontSize={'xl'} fontWeight='bold' color={'white'}>
              {props.score}
            </Text>
          </Circle>
        </HStack>
      }
      <VStack spacing={3}>
        <Box bgSize="cover" w="auto" h={100}>
          <Image
            w="100%"
            h="90%"
            objectFit="scale-down"
            src={
              !imageUrl.imageUrl === null
                ? '../loading-image.png'
                : imageUrl.imageUrl
            }
          />
        </Box>
        <Text size="md" color={"truvalGrey.400"} fontWeight={'bold'}>
          {props.company.name}
        </Text>
      </VStack>
    </Box >
  );
};

export default CompanyCard;
