import React, { useEffect } from 'react';
import { Link, } from 'react-router-dom';
import { Box, Flex, Text, Button, Image } from '@chakra-ui/react';

import { InfoButton, QuizHeader } from '../components';

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

export const QuizIntro = () => {
  useEffect(() => {
    // Send Analytics.
    mixpanel.init(MIXPANEL_ID);
    mixpanel.track('Page Viewed', {
      'Screen Name': 'Quiz Intro',
      'Platform': 'WWW',
    });
  }, []);

  const infoButtonMessage =
    'Find out which brands you should support, and which ones you should avoid by taking this quiz.  To receive your personalized scores for 2,000+ brands, please consider installing our mobile app for iOS or Android today.';
  return (
    <Box bg="truvalGreen.300" color="white" paddingBottom={{ base: '200px', md: '800px' }}>
      <QuizHeader />
      <Flex
        display={'flex'}
        flexDirection="column"
        justifyContent="center"
        alignItems={'center'}
        textAlign={'center'}
        mx={5}
      >
        <Text
          my={3}
          color="truvalyouBlue.300"
          fontSize={{ base: 'lg', md: '3xl' }}
          fontWeight={'bold'}
        >
          Do you know which brands align with your values?
        </Text>
        <Image src={'./img/quizintroimage-page-001.jpg'} h={'300px'} />
        <Text my={3} fontSize={{ base: 'md', md: 'lg' }} color="truvalyouBlue.300">
          Search for a brand, select 3 values, and get your personalized score out of 100 indicating how aligned they are with you.
        </Text>
        {/* <Text my={3} fontSize={{ base: 'md', md: 'lg' }} color="truvalyouBlue.300" alignItems={'start'}>
          Search for a brand you shop from.
        </Text>
        <Text my={1} fontSize={{ base: 'md', md: 'lg' }} color="truvalyouBlue.300">
          Select values you support
        </Text>
        <Text my={1} fontSize={{ base: 'md', md: 'lg' }} color="truvalyouBlue.300">
          Recieve your personalized score out of 100 indicating how aligned they are with your values.
        </Text> */}
        {/* <Text my={3} fontSize={'lg'} color="white">
          We'll provide you with your personalized rating out of 100 indicating
          how aligned the company you've selected is with your beliefs!
        </Text>
        <Text my={3} color="white" fontSize={'xl'}>
          Let's make belief-driven buying the new normal together, get started below!
        </Text> */}
        <Box my={4} >
          <Link to={'/companySelect'}>
            <Button color={'white'} bg={'truvalyouBlue.300'}>Get Started!</Button>
          </Link>
        </Box>
      </Flex>
      {/* <Box pos="fixed" bottom="5" right="5">
        <InfoButton message={infoButtonMessage} />
      </Box> */}
    </Box >
  );
};
