import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { db } from '../firebase/config.js';
import { doc, getDoc } from 'firebase/firestore';

import { CompanyCard, InfoButton, QuizHeader } from '../components';

import {
  Box,
  Flex,
  Text,
  Heading,
  HStack,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react';

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

export const CompanyAlign = () => {
  useEffect(() => {
    // Send Analytics.
    mixpanel.init(MIXPANEL_ID);
    mixpanel.track('Page Viewed', {
      'Screen Name': 'Quiz Company Align',
      'Platform': 'WWW',
    });
  }, []);

  const { id } = useParams();

  const navigate = useNavigate();
  const [isCancelled, setIsCancelled] = useState(false);
  useEffect(() => {
    return () => { setIsCancelled(true); }
  }, []);

  const [companyDetails, setCompanyDetails] = useState({ name: '' });

  useEffect(() => {
    try {
      const docCompany = doc(db, 'companies', id);

      getDoc(docCompany).then((doc) => {
        if (!isCancelled) {
          setCompanyDetails(doc.data());
        }
        // console.log(companyDetails);
      });
    } catch (e) {
      // console.log(e);
    }
  }, [id, isCancelled]);

  const [sliderValue, setSliderValue] = useState(50);

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
    fontWeight: 'bold'
  };

  const infoButtonMessage =
    "We're on a mission to make belief-driven buying easier than ever for consumers across the globe. That means consumers need to know which brands align with their beliefs, and which ones do not. To receive personalized ratings for 2,000+ brands, please consider installing our mobile app for iOS or Android today.";
  return (
    <Box bg="truvalGreen.200" paddingBottom={{ base: '200px', md: '800px' }}>
      <QuizHeader />
      <Flex
        bg="truvalGreen.200"
        flexDirection="column"
        justifyContent="center"
        alignItems={'center'}
        textAlign={'center'}
        display={'flex'}
      >
        <Heading as="h4" size="lg" variant="white" my={2} px={'5px'}>
          How aligned do you think {companyDetails.name} is with your values?
        </Heading>
        <Box justifyContent="center" display={'flex'} p={'35px'}>
          <CompanyCard company={companyDetails} />
        </Box>
        <Box pt={12} pb={12} w={{ base: '80%', md: '60%' }}>
          <Slider
            aria-label="slider-ex-6"
            onChange={(val) => setSliderValue(val)}
            color="white"
            defaultValue={50}
            step={50}
          >
            <SliderMark value={0} {...labelStyles}>
              UNALIGNED
            </SliderMark>
            <SliderMark value={45} {...labelStyles}>
              NEUTRAL
            </SliderMark>
            {/* Below is a bit ugly but necessary to get the SliderMark data to look correct
            across both mobile and desktop. (jdy)
             */}
            <SliderMark value={85} {...labelStyles} display={{ base: 'block', md: 'none' }}>
              ALIGNED
            </SliderMark>
            <SliderMark value={95} {...labelStyles} display={{ base: 'none', md: 'block' }}>
              ALIGNED
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Box>
        <HStack spacing={{ base: 5 }} my={5}>
          <Button w="8rem" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Link
            exact="true"
            to={`/valuesSelect/${id}`}
            state={{
              companyAlign: sliderValue,
              companyDetails: companyDetails,
            }}
            className="navLink"
          >
            <Button w="8rem">Next</Button>
          </Link>
        </HStack>
        <Text color="white" mt={2} fontWeight={'bold'}>
          Step 2/3
        </Text>
      </Flex>
      <Box pos="fixed" bottom="5" right="5">
        <InfoButton message={infoButtonMessage} />
      </Box>
    </Box >
  );
};
