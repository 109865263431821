import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';

import { db } from '../firebase/config.js';

import {
  doc,
  getDoc,
  collection,
  getDocs,
  setDoc,
  serverTimestamp,
  query,
} from 'firebase/firestore';

import { v4 as uuidv4 } from 'uuid';

import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Heading,
  HStack,
  Button,
  useCheckboxGroup,
} from '@chakra-ui/react';

import { ValuesCard, InfoButton, QuizHeader } from '../components';

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

export const ValuesSelect = () => {
  useEffect(() => {
    // Send Analytics.
    mixpanel.init(MIXPANEL_ID);
    mixpanel.track('Page Viewed', {
      'Screen Name': 'Quiz Value Select',
      'Platform': 'WWW',
    });
  }, []);

  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isCancelled, setIsCancelled] = useState(false);
  useEffect(() => {
    return () => { setIsCancelled(true); }
  }, []);

  const [companyDetails, setCompanyDetails] = useState({ name: '' });
  useEffect(() => {
    try {
      const docCompany = doc(db, 'companies', id);

      getDoc(docCompany).then((doc) => {
        if (!isCancelled) {
          setCompanyDetails(doc.data());
        }
        // console.log(companyDetails);
      });
    } catch (e) {
      // console.log(e);
    }
  }, [id, isCancelled]);

  const [valuesConnected, setValuesConnected] = useState([]);
  useEffect(() => {
    try {
      const q = query(collection(db, `companies/${id}/revolutions`));

      let valuesBank = [];
      getDocs(q).then((snapshot) => {
        snapshot.forEach((doc) => {
          valuesBank.push(doc.data());
        });
        if (!isCancelled) {
          setValuesConnected(valuesBank);
        }
      });
    } catch (e) {
      // console.log(e);
    }
  }, [id, isCancelled]);

  const [checked, setChecked] = useState([]);
  const { getCheckboxProps } = useCheckboxGroup({ onChange: setChecked });

  const uniqueId = uuidv4();

  const onSubmitQuiz = async () => {
    let supportSelectedValues = 0;
    valuesConnected.forEach((data) => {
      checked.forEach((selectedValues) => {
        if (selectedValues === data.rid && data.support === true) {
          supportSelectedValues++;
        }
      });
    });
    // console.log(supportSelectedValues);
    // console.log(checked.length);
    // const userScore = 0;
    const userScore = parseInt(
      (supportSelectedValues / checked.length) * 100,
      10
    );
    // console.log(userScore);

    // let correct = '';
    let aligned = '';
    if (userScore < 30) {
      aligned = 'unaligned';
    } else if ((30 <= userScore) && (userScore < 70)) {
      aligned = 'neutral';
    } else if (userScore >= 70) {
      aligned = 'aligned';
    }
    // if (
    //   Math.abs(location.state.companyAlign - location.state.userScore) <= 10
    // ) {
    //   correct = 'correct';
    // } else {
    //   correct = 'incorrect';
    // }
    // console.log(correct, aligned);

    const q = query(collection(db, 'quiz-results'));

    getDocs(q).then((snapshot) => {
      let companyBank = [];
      snapshot.forEach((doc) => {
        companyBank.push(doc.data());
      });
      // console.log(companyBank);
    });
    try {
      await setDoc(
        doc(db, 'quiz-results', uniqueId),
        {
          qid: uniqueId,
          cid: id,
          companyDetails: companyDetails,
          // companyAlign: location.state.companyAlign
          //   ? location.state.companyAlign
          //   : 0,
          userValuesSelected: checked,
          userScore: userScore,
          // correct: correct,
          aligned: aligned,
          createDate: serverTimestamp(),
          timestamp: serverTimestamp(),
        },
        { merge: true }
      );
    } catch (e) {
      // console.log(e);
    }
  };

  const infoButtonMessage =
    'This is a list of all values that have rated company_name as either supported or avoided. To view the full list of values, please consider installing our mobile app for iOS or Android today.';
  return (
    <Box bg="truvalGreen.300" paddingBottom={{ base: '200px', md: '800px' }}>
      <QuizHeader />
      <Flex
        bg="truvalGreen.300"
        flexDirection="column"
        justifyContent="center"
        alignItems={'center'}
        textAlign={'center'}
        display={'flex'}
      >
        <Heading
          my={3}
          color="truvalyouBlue.300"
          fontSize={{ base: 'md', md: 'xl' }}
          fontWeight={'bold'}
          w={{ base: '90%', md: '80%' }}
        >
          Select at least 3 values you support to receive your score for {companyDetails.name}.
        </Heading>
        {/* <Text color="white">
          If you do not support any of these values, please search for another
          company.
        </Text> */}
        <Box
          w="100%"
          alignContent="center"
          alignItems={['center', 'center', 'flex-start']}
          justifyContent="center"
          alignSelf="center"
          textAlign={'center'}
          display={'flex'}
          my={2}
        >
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing="0px"
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            display={'grid'}
            w={{ base: '95%', md: '100vh', lg: '60%' }}
            gap={2}
          >
            {valuesConnected.map((data) => {
              const value = data.rid;
              const checkbox = getCheckboxProps({ value });
              // console.log(value);
              return (
                <Box key={data.rid} justifyContent="center" display={'flex'}>
                  <ValuesCard {...checkbox} />
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
        <HStack spacing={{ base: 5 }} my={5}>
          <Button
            w="8rem"
            color={'white'}
            bg={'truvalyouBlue.300'}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Link
            exact="true"
            to={`/companyScore/${uniqueId}`}
            state={{
              userAnswer: 1,
              companyDetails: companyDetails,
            }}
          >
            <Button
              w="8rem"
              color={'white'}
              bg={'truvalyouBlue.300'}
              onClick={onSubmitQuiz}
              disabled={checked.length === 0 ? true : false}
            >
              Next
            </Button>
          </Link>
        </HStack>
        <Text color={'truvalyouBlue.300'} mt={1} fontWeight={'bold'}>
          Step 2/3
        </Text>
      </Flex>
      {/* <Box pos="fixed" bottom="5" right="5">
        <InfoButton message={infoButtonMessage} />
      </Box> */}
    </Box>
  );
};
