import { useState, useEffect } from 'react';

// Imports for Google Storage files
import { storage, PROJECT_ID, EMULATOR } from '../firebase/config.js';
import { ref, getDownloadURL, } from 'firebase/storage';

export const useStorageDownloadUrl = (esImageUrl, gsUrl) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    // console.log(esImageUrl);
    try {
      if (String(esImageUrl).includes('http')) {
        // console.log(`Setting HTTP ${esImageUrl}`);
        setImageUrl(esImageUrl);
      }
      else if (gsUrl) {
        // console.log(gsUrl);
        // Let's get a thumbnail address...
        // Split our file path down in to chunks we can re-join
        var filePath = gsUrl;
        var fileComponents = filePath.split('/');
        var filename = fileComponents[4].split('.');

        var hostPath = EMULATOR ? PROJECT_ID + '.appspot.com' : fileComponents[2];

        var thumbPath = fileComponents[0]
          + '//'
          + hostPath
          + '/'
          + fileComponents[3]
          + '/thumbs/'
          + filename[0]
          + '_200x200.'
          + filename[1];

        var fullPath =
          fileComponents[0]
          + '//'
          + hostPath
          + '/'
          + fileComponents[3]
          + '/'
          + filename[0]
          + '.'
          + filename[1];
        // console.log(thumbPath);

        var gsRef = ref(storage, thumbPath);

        getDownloadURL(gsRef).then((httpUrl) => {
          // console.log(httpUrl);
          // return httpUrl;

          setImageUrl(httpUrl);

        }).catch((e) => {
          // console.log(e);
          // if we failed on thumb URL, try the full image
          var gsRef = ref(storage, fullPath);

          getDownloadURL(gsRef).then((httpUrl) => {
            // console.log(httpUrl);
            // return httpUrl;

            setImageUrl(httpUrl);

          }).catch((e) => {
            console.log(e);

          });
        });
      }
    } catch (e) {
      console.log(e);

      // return null;
    }
  }, [esImageUrl, gsUrl]);

  return { imageUrl }
}