import { useState, useEffect } from 'react';

import { functions, db } from '../firebase/config.js';
import { httpsCallable } from 'firebase/functions';

import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';

export const useEsQuizCompaniesSearch = () => {
  const [quizCompaniesList, setQuizCompanyList] = useState([]);
  const [loadingQuizCompanyList, setLoadingQuizCompanyList] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const esQuizCompanySearch = async (searchKey) => {
    try {
      let getQuizCompanySearch = httpsCallable(
        functions,
        'searchElasticQuizCompany'
      );
      // console.log(query);

      setLoadingQuizCompanyList(true);
      await getQuizCompanySearch({ searchQuery: searchKey }).then((results) => {
        try {
          if (searchKey !== '') {
            let docs = results.data.hits.hits;
            let data = [];
            docs.forEach((value) => {
              data = [...data, value._source];
            });
            setQuizCompanyList(data);
            setLoadingQuizCompanyList(false);
          } else if (searchKey === '') {
            const q = query(
              collection(db, 'quiz-companies'),
              orderBy('total_count', 'desc'),
              limit(4)
            );
            let companyBank = [];
            getDocs(q).then((snapshot) => {
              snapshot.forEach((doc) => {
                companyBank.push(doc.data());
              });
              // console.log(isCancelled)
              if (!isCancelled) {
                setQuizCompanyList(companyBank);
                // console.log(companyBank);
                setLoadingQuizCompanyList(false);
              }
            });
          }
          // console.log(quizCompaniesList);
        } catch (e) {
          // console.log(e);
        }
      });
    } catch (e) {
      // console.log(e);
      setLoadingQuizCompanyList(false);
    }
  };

  // setLoadingQuizCompanyList(false);

  useEffect(() => {
    return () => setIsCancelled(false);
  }, []);

  return { esQuizCompanySearch, quizCompaniesList, loadingQuizCompanyList };
};
