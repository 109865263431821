import React, { useEffect } from 'react';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID, ENVIRONMENT, VERSION } from '../firebase/config.js';

// Card imports
import { Box, Text, Stack, HStack } from '@chakra-ui/react';

export default function QuizShareList({ quiz, message }) {
  // Init mixpanel
  useEffect(() => {
    mixpanel.init(MIXPANEL_ID, { debug: ENVIRONMENT === 'dev' });
  }, []);

  const quizUrl = 'https://app.truvalyou.com/companyScore/' + quiz.qid;

  // console.log(quiz);
  return (
    <Box data-component={'quiz-share-component'} px={1} bg={'truvalyouBlue.300'} py={'25px'}>
      <Stack
        w={{ base: '100%' }}
        px={{ base: 1, md: 4 }}
        justifyContent={'center'}
        direction="column"
        align={'center'}
        alignItems={'center'}
      >
        <Text fontSize={{ base: 'xl', md: 'xl' }} color={'white'} px={2}>
          {message ? message : 'Take action, share this list!'}
        </Text>
        <Box py={'2px'} />
        <HStack paddingBottom={'10px'} justify={'space-evenly'}>
          <TwitterShareButton
            url={quizUrl}
            title={
              `I'm ${quiz.align === 'aligned' ? 'supporting' : 'avoiding'} ` +
              quiz.companyDetails.name +
              ' based on my personal values. Take the quiz on truvalyou to discover which brands you should support or avoid today! quiz_link'
            }
            related={['@truvalyouapp']}
            onClick={() => {
              mixpanel.track('Share', {
                Type: 'quiz',
                'quiz ID': quiz.qid,

                Medium: 'Twitter',
                Origin: 'quiz Details',
                Platform: 'Web',
                Version: VERSION,
              });
            }}
          >
            <TwitterIcon size={'48px'} round />
          </TwitterShareButton>

          <FacebookShareButton
            url={quizUrl}
            quote={
              `I'm ${quiz.align === 'aligned' ? 'supporting' : 'avoiding'} ` +
              quiz.companyDetails.name +
              ' based on my personal values. Take the quiz on truvalyou to discover which brands you should support or avoid today! quiz_link'
            }
            onClick={() => {
              mixpanel.track('Share', {
                Type: 'quiz',
                'quiz ID': quiz.id,

                Medium: 'Facebook',
                Origin: 'quiz Details',
                Platform: 'Web',
                Version: VERSION,
              });
            }}
          >
            <FacebookIcon size={'48px'} round />
          </FacebookShareButton>

          <LinkedinShareButton
            url={quizUrl}
            title={quiz.name + ' on truvalyou.'}
            summary={
              `I'm ${quiz.align === 'aligned' ? 'supporting' : 'avoiding'} ` +
              quiz.companyDetails.name +
              ' based on my personal values. Take the quiz on truvalyou to discover which brands you should support or avoid today! quiz_link'
            }
            onClick={() => {
              mixpanel.track('Share', {
                Type: 'quiz',
                'quiz ID': quiz.qid,

                Medium: 'Linkedin',
                Origin: 'quiz Details',
                Platform: 'Web',
                Version: VERSION,
              });
            }}
          >
            <LinkedinIcon size={'48px'} round />
          </LinkedinShareButton>

          <WhatsappShareButton
            url={quizUrl}
            title={
              `I'm ${quiz.align === 'aligned' ? 'supporting' : 'avoiding'} ` +
              quiz.companyDetails.name +
              ' based on my personal values. Take the quiz on truvalyou to discover which brands you should support or avoid today! quiz_link'
            }
            separator={': '}
            onClick={() => {
              mixpanel.track('Share', {
                Type: 'quiz',
                'quiz ID': quiz.qid,

                Medium: 'Whatsapp',
                Origin: 'quiz Details',
                Platform: 'Web',
                Version: VERSION,
              });
            }}
          >
            <WhatsappIcon size={'48px'} round />
          </WhatsappShareButton>

          <TelegramShareButton
            url={quizUrl}
            title={
              `I'm ${quiz.align === 'aligned' ? 'supporting' : 'avoiding'} ` +
              quiz.companyDetails.name +
              ' based on my personal values. Take the quiz on truvalyou to discover which brands you should support or avoid today! quiz_link'
            }
            onClick={() => {
              mixpanel.track('Share', {
                Type: 'quiz',
                'quiz ID': quiz.qid,

                Medium: 'Telegram',
                Origin: 'quiz Details',
                Platform: 'Web',
                Version: VERSION,
              });
            }}
          >
            <TelegramIcon size={'48px'} round />
          </TelegramShareButton>

          {/* <FacebookMessengerShareButton url={quizUrl}>
              <FacebookMessengerIcon size={32} round/>
            </FacebookMessengerShareButton> */}
          <EmailShareButton
            url={quizUrl}
            subject={'Check out this quiz on Truvalyou App'}
            body={
              `I'm ${quiz.align === 'aligned' ? 'supporting' : 'avoiding'} ` +
              quiz.companyDetails.name +
              ' based on my personal values. Take the quiz on truvalyou to discover which brands you should support or avoid today! quiz_link'
            }
            separator={': '}
            onClick={() => {
              mixpanel.track('Share', {
                Type: 'quiz',
                'quiz ID': quiz.qid,

                Medium: 'Email',
                Origin: 'quiz Details',
                Platform: 'Web',
                Version: VERSION,
              });
            }}
          >
            <EmailIcon size={'48px'} round />
          </EmailShareButton>
        </HStack>
      </Stack>
    </Box>
  );
}
