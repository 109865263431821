import React, { useState } from 'react'

// Card imports
import { Stack, Text, Image, Flex, } from '@chakra-ui/react';

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from '@chakra-ui/react';

// Imports for Google Storage files
import { useStorageDownloadUrl } from '../hooks/useStorageDownloadUrl';
import PlayStoreButton from './PlayStoreButton';
import AppStoreButton from './AppStoreButton';

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

export default function CompanyRow({ company }) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState('md');

  const handleSizeClick = (newSize) => {
    setSize(newSize)
    onOpen();
  };

  // console.log(company.thumbUrl);

  const imageUrl =
    useStorageDownloadUrl(company.thumbUrl, company.companyImageName ? company.companyImageName : company.imageName);

  // console.log(company);

  return (
    <>
      <Flex
        align="center"
        onClick={() => {
          handleSizeClick('lg');

          mixpanel.init(MIXPANEL_ID);
          mixpanel.track('Page Viewed', {
            'Screen Name': 'Company Result Modal',
            'Company ID': company.cid,
            'Company Name': company.name,
            'Platform': 'WWW',
          });
        }}
      >
        <Image src={imageUrl.imageUrl} boxSize={'35px'} objectFit='contain' />
        <Text align={'start'} paddingLeft={'10px'}>{company.name}</Text>
      </Flex >

      <Modal onClose={onClose} size={size} isOpen={isOpen} >
        <ModalOverlay />
        <ModalContent bg={'#00ABB6'}>
          <ModalHeader>
            <Flex align="center" >
              <Image src={imageUrl.imageUrl} boxSize={'75px'} objectFit='contain' />&nbsp;&nbsp;&nbsp;
              <Text color={'#010051'} fontSize={'22'}> {company.name}</Text>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Text variant={'modalContent'}>
              Values rate companies as supported or avoided in the app.<br /><br />
            </Text>
            <Text variant={'modalContent'}>
              Currently {company.name} has,
            </Text>
            <br />
            <Stack dirction={'row'} paddingLeft={'50px'}>
              <Stack direction={'row'} align={'baseline'}>
                <Text variant={'modalNumber'}>{company.support ? company.support : '0'}</Text>
                <Text variant={'modalNumberText'}>Values Supporting</Text>
              </Stack>
              <Stack direction={'row'} align={'baseline'}>
                <Text variant={'modalNumber'}>{company.against ? company.against : '0'}</Text>
                <Text variant={'modalNumberText'}>Values Avoiding</Text>
              </Stack>
            </Stack>
            <br />
            <Text fontSize={{ base: 'sm', md: 'sm' }} color={'#010051'}>
              Download the app to follow your values and receive personalized scores for 2,000+ brands including {company.name} today!
            </Text>
            <Stack direction={{ base: 'column', md: 'column' }} align={'center'}>
              <>
                {/* Download the app */}
                <Stack justify={'center'} align={'center'} direction={'row'} paddingTop={'15px'}>
                  <AppStoreButton />
                  <PlayStoreButton />
                </Stack>
              </>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>

  );
}
