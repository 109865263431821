import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Text,
  Stack,
  Container,
  Flex,
  Heading,
  ListItem,
  List,
  Image,
} from '@chakra-ui/react';

import { motion, useScroll } from 'framer-motion';

import move from 'lodash-move';

export default function TakeTheQuiz() {
  const CARD_IMAGES = [
    'card_fedex2x',
    'card_starbucks2x',
    'card_amazon2x',
    'card_walmart2x',
  ];
  // const CARD_IMAGES = ['#266678', '#cb7c7a', ' #36a18b', '#cda35f', '#747474'];
  const CARD_OFFSET = 20;
  const SCALE_FACTOR = 0.06;

  const [cards, setCards] = useState(CARD_IMAGES);
  const moveToEnd = (from) => {
    setCards(move(cards, 0, cards.length - 1));
    // console.log(cards);
  };

  const { scrollY } = useScroll();
  const [canUpdate, setCanUpdate] = useState(true);

  scrollY.onChange((latest) => {
    // console.log('Page scroll: ', Math.ceil(latest / 10) % 29);

    if (Math.ceil(latest / 10) % 23 === 0 && canUpdate) {
      setCanUpdate(false);
      moveToEnd(0);
    } else if (Math.ceil(latest / 10) % 33 === 0 && !canUpdate) {
      setCanUpdate(true);
    }
  });

  return (
    // <Box bgGradient="linear(truvalyouBlue.300 10%,  truvalGreen.300 100%)">
    <Box bgGradient="linear(truvalGreen.300 10%, truvalyouBlue.300 100% )">
      <Container maxW={'5xl'} py={{ base: '20px', md: '20px' }} centerContent>
        <Box
          bg={'truvalGreen.100'}
          py={'20px'}
          borderRadius="xl"
          boxShadow={'2xl'}
          position="relative"
          z-index="2"
          borderColor={'white'}
          // borderimage={'gray.400'}
          borderWidth={'5px'}
        >
          <Flex
            columns={{ base: 2, md: 2 }}
            spacing={5}
            direction={{ base: 'column-reverse', md: 'row' }}
            mx={2}
          >
            <Stack
              spacing={2}
              justify={{ md: 'space-evenly' }}
              display="flex"
              alignItems="center"
            >
              <Heading
                image={'white'}
                fontSize={{ base: 'lg', md: '3xl' }}
                textAlign={{ base: 'center', md: 'left' }}
                paddingLeft={{ base: '10px', md: '20px' }}
                paddingRight={{ base: '10px', md: '0px' }}
                color="truvalyouBlue.300"
              >
                Do you know which brands align with your values?
              </Heading>
              {/* <Text
                image={'white'}
                fontSize={{ base: 'md', md: 'lg' }}
                textAlign={{ base: 'center', md: 'center' }}
                paddingLeft={{ base: '10px', md: '20px' }}
                paddingRight={{ base: '10px', md: '0px' }}
                color="truvalyouBlue.300"
              >
                Take our quiz and find out!
              </Text> */}

              <Link to={'/quizIntro'} display="flex" justify="center">
                <Box as="button">
                  <Text
                    textTransform={'uppercase'}
                    image={'white'}
                    fontWeight={600}
                    fontSize={'lg'}
                    bg={'#020084'}
                    px={'3'}
                    py={'2'}
                    alignSelf={'center'}
                    rounded={'md'}
                    color="white"
                  >
                    Take the Quiz
                  </Text>
                </Box>
              </Link>
            </Stack>
            <Stack
              paddingTop={{ base: '5px', md: '10px' }}
              paddingLeft={{ base: '20px', md: '20px' }}
              paddingRight={{ base: '20px', md: '20px' }}
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                paddingTop={'5'}
              >
                <List
                  position="relative"
                  w="8rem"
                  h="10rem"
                  display="flex"
                  alignItems="center"
                >
                  {cards.map((image, index) => {
                    return (
                      <ListItem
                        className="list-image"
                        as={motion.li}
                        key={image}
                        p={5}
                        width="8rem"
                        // height="24rem"
                        borderRadius="8px"
                        padding="10px"
                        position="absolute"
                        bg={image}
                        animate={{
                          top: index * -CARD_OFFSET,
                          scale: 1 - index * SCALE_FACTOR,
                          zIndex: CARD_IMAGES.length - index,
                          transition: { duration: 0.5 },
                        }}
                        dragConstraints={{
                          top: 0,
                          bottom: 0,
                        }}
                        onClick={() => {
                          moveToEnd(0);
                        }}
                      >
                        <Box
                          // bgImage={'./img/' + image + '.png'}
                          bgPosition="center"
                          bgRepeat="no-repeat"
                          borderRadius={'3xl'}
                          boxShadow="2xl"
                          w="100%"
                        >
                          <Image
                            alt={'feature image'}
                            // src={'./img/card_fedex2x.png'}
                            src={'./img/' + image + '.webp'}
                            boxSize="16rem"
                            w="100%"
                            h="100%"
                            objectFit="cover"
                          />
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Stack>
          </Flex>
        </Box>
      </Container>
    </Box >
  );
}
