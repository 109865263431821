import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
// import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, ref, connectStorageEmulator } from "firebase/storage";

const VERSION = 'v2022.06.06';
// ENVIRONMENT Options: prod, qa, dev
const ENVIRONMENT = 'prod';
const EMULATOR = false;

// Prod
const firebaseProdConfig = {
  apiKey: "AIzaSyDURPwIwFN6dALSDpOWSoO2OAodrTfyktM",
  authDomain: "truvalyou.firebaseapp.com",
  projectId: "truvalyou",
  storageBucket: "truvalyou.appspot.com",
  messagingSenderId: "178300532473",
  appId: "1:178300532473:web:92b7974c4c2b9a920f9428",
  measurementId: "G-5Q4DJHWVE7"
};
const PROD_PROJECT_ID = 'truvalyou';
const PROD_MIXPANEL_ID = 'f941e9d591d6a54cbeed9093684c819f';

// Dev 
const firebaseDevConfig = {
  apiKey: "AIzaSyBaNk2YEAqr-oJjpqs6MOoEI3NeniCVtJI",
  authDomain: "prevdev-bbd7d.firebaseapp.com",
  databaseURL: "https://prevdev-bbd7d-default-rtdb.firebaseio.com",
  projectId: "prevdev-bbd7d",
  storageBucket: "prevdev-bbd7d.appspot.com",
  messagingSenderId: "554402964333",
  appId: "1:554402964333:web:9871d4f01a115d3a727064",
  measurementId: "G-5LLQ11LDRH",
  client_id: "com.truvalyou.dwapp"
};
const DEV_PROJECT_ID = 'prevdev-bbd7d';

// Emulator
const firebaseEmulatorConfig = {
  apiKey: "AIzaSyDkI0ZDYV-CKEgjvHxz4EFmq74Ledw1m7Q",
  authDomain: "jesse-dev.firebaseapp.com",
  projectId: "jesse-dev",
  storageBucket: "jesse-dev.appspot.com",
  messagingSenderId: "282588399162",
  appId: "1:282588399162:web:7b90da75da8987c7fc1703"
};

// Prod and QA will use the production firebase setup.
const firebaseConfig = EMULATOR ? firebaseEmulatorConfig : (ENVIRONMENT === 'prod' || ENVIRONMENT === 'qa') ? firebaseProdConfig : firebaseDevConfig;
const PROJECT_ID = EMULATOR ? 'jesse-dev' : (ENVIRONMENT === 'prod' || ENVIRONMENT === 'qa') ? PROD_PROJECT_ID : DEV_PROJECT_ID;
// const PROJECT_ID = EMULATOR ?? 'jesse-dev';
// const PROJECT_ID = "jesse-dev";


// QA and DEV will use DEVELOPMENT analytics systems
const MIXPANEL_ID = PROD_MIXPANEL_ID;

// init Firebase
const firebaseApp = initializeApp(firebaseConfig);

// init firestore
const db = EMULATOR ? getFirestore() : getFirestore(firebaseApp);

// init functions
const functions = EMULATOR ? getFunctions() : getFunctions(firebaseApp);

// init storage
const storage = EMULATOR ? getStorage() : getStorage(firebaseApp);
const storagePathRef = ref(storage, 'extension/db.txt');

// Let's check if the emulator is in use, if so we will re-init firebase.
if (EMULATOR) {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// const mp = mixtemp.tvy;


export { firebaseConfig, db, functions, storage, storagePathRef, ENVIRONMENT, EMULATOR, PROJECT_ID, MIXPANEL_ID, VERSION }
