import {
  Box,
  SimpleGrid,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons'

export default function OnAMission() {
  return (
    <Box py={'10px'} px={'5px'} bg={'truvalGreen.100'}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 8 }}
        py={{ base: '10px', md: '10px' }}
        px={{ base: '5px', md: '50px' }}
      >
        <Stack
          direction={{ base: 'column', md: 'column' }}
          align={{ base: 'start', md: 'start' }}
        >
          <Text
            fontSize={'5xl'}
            fontWeight={'bold'}
            color={'truvalyouBlue.300'}
          >
            OUR MISSION
          </Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'column' }}
          align={'start'}
        >
          {/* <Text fontSize={'md'}>Welcome to truvalyou!</Text> */}
          <Text
            fontSize={'md'}
            color={'truvalyouBlue.300'}
            align={'start'}
          >
            We want to make&nbsp;
            <Link
              href={'https://www.edelman.com/news-awards/two-thirds-consumers-worldwide-now-buy-beliefs'}
              isExternal
            >
              belief-driven buying <ExternalLinkIcon mx='2px' />
            </Link>

            &nbsp;the next big thing.
          </Text>

          <Text
            fontSize={'md'}
            color={'truvalyouBlue.300'}
            align={'start'}
          >
            We believe that consumers have the power to shape the world by voting with their wallets.&nbsp;
            Join our cause and let’s change the world together, one purchase at a time!
          </Text>

        </Stack>
      </SimpleGrid>
    </Box >
  )
}