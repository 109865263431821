// import { ReactNode } from 'react';
import {
  Box,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  Center,
  Divider,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaTwitter, FaInstagram, } from 'react-icons/fa';
import { GrFacebookOption, GrLinkedinOption } from 'react-icons/gr';

import PlayStoreButton from './PlayStoreButton';
import AppStoreButton from './AppStoreButton';

const SocialButton = ({
  children,
  label,
  href,
}) => {
  return (
    <chakra.button
      bg={'truvalyouBlue.300'}
      rounded={'full'}
      w={12}
      h={12}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      // transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg={'truvalGreen.300'}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8} py={'15px'}>

        <Stack align={'center'}>
          <Text variant={'footerHeader'}>CORPORATE</Text>
          <Text variant={'footerText'}><Link href={'mailto:info@truvalyou.com'}>Contact</Link></Text>
          <Text variant={'footerText'}><Link href={'https://g1572lnltss.typeform.com/to/moS2Lbpr'}>Moderator Request</Link></Text>
        </Stack>

        <Stack align={'center'}>
          <Text variant={'footerHeader'}>LEGAL</Text>
          <Text variant={'footerText'}><Link href={'https://www.truvalyou.com/privacy.html'} isExternal>Privacy Policy</Link></Text>
          <Text variant={'footerText'}><Link href={'https://www.truvalyou.com/terms.html'} isExternal>Terms of Service</Link></Text>
        </Stack>

        <Stack align={'center'}>
          <Text variant={'footerHeader'}>DOWNLOAD OUR APP</Text>
          <AppStoreButton />
          <PlayStoreButton />
        </Stack>
      </SimpleGrid>

      <Center paddingTop={'15px'}>
        <Stack direction={'column'}>
          <Text align={'center'} variant={'footerFollow'}>Follow Us</Text>
          <Stack direction={'row'} spacing={6} >
            <SocialButton label={'Twitter'} href={'https://twitter.com/truvalyouapp'}>
              <FaTwitter size={'32px'} color={'white'} />
            </SocialButton>
            <SocialButton label={'Facebook'} href={'https://www.facebook.com/truvalyou'}>
              <GrFacebookOption size={'32px'} color={'white'} />
            </SocialButton>
            <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/company/truvalyou'}>
              <GrLinkedinOption size={'32px'} color={'white'} />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'https://www.instagram.com/truvalyou/'}>
              <FaInstagram size={'32px'} color={'white'} />
            </SocialButton>
          </Stack>
          <Center py={'5px'}>
            <Text variant={'footerHeader'}>#drivenbuybelief</Text>
          </Center>
        </Stack>
      </Center>

      <Box px={'25px'} py={'15px'}
        color={'truvalyouBlue.300'}>
        <Divider orientation='horizontal' borderColor={'truvalyouBlue.300'} />
        <Text variant={'footerText'} paddingTop={'5px'}>Copyright © 2022 Jajit Pte Ltd. All Rights Reserved</Text>
      </Box>
    </Box >
  );
}