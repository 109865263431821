import {
  Box,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons'

export default function ModeratorRequest() {
  return (
    <Box py={'10px'} px={'5px'} bg={'truvalGreen.100'}>
      <Stack
        direction={{ base: 'column', md: 'column' }}
        justify={'center'}
        align={'center'}
        py={{ base: '10px', md: '10px' }}
        px={{ base: '5px', md: '50px' }}
      >
        <Text variant={'whatSubText'} align={'center'} as={'i'} color={'truvalyouBlue.300'}>
          All brand ratings are submitted by moderators that are part of our user community.  If you would like to become a member of our moderator team and rate companies as supported or avoided, please fill out the form&nbsp;
          <Link
            href={'https://g1572lnltss.typeform.com/to/moS2Lbpr'}
            isExternal
          >
            here. <ExternalLinkIcon mx='2px' />
          </Link>
        </Text>
      </Stack>
    </Box >
  )
}