import {
  Box,
  SimpleGrid,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

export default function WhatWeDoForYou() {

  return (
    <Box bg={'truvalGreen.300'} py={'30px'}>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 5, lg: 8 }}
        py={{ base: '10px', md: '20px' }}
        px={{ base: '15px', md: '40px' }}
      >
        <Stack
          direction={{ base: 'column', md: 'column' }}
          justify={'center'}
          align={'center'}
        >
          <Text
            variant={'whatHeader'}
            align={{ base: 'start', md: 'center' }}
          >
            What truvalyou Will Do For You
          </Text>
          <Text
            variant={'whatHeaderText'}
            align={{ base: 'start', md: 'center' }}
          >
            We empower consumers to support or avoid brands based on their unique values.&nbsp;&nbsp;
            <Box display={{ base: 'none', md: 'block' }} />
            Simply sign up, follow values you believe in, and receive personalized ratings and recommendations for 2,000+ brands based on how aligned they are with your values.
          </Text>
        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 3 }}
        >
          <Stack direction={'column'} justify={'space-evenly'}>
            <Stack
              direction={{ base: 'column', md: 'column' }}
              justify={'start'}
              align={'start'}
            >
              <Image src={'./follow.svg'} boxSize={'48px'} />
              <Text variant={'whatSubHeader'}>
                Follow Values
              </Text>
              <Text variant={'whatSubText'}>
                Discover and follow values you believe in to receive your personalized brand ratings and recommendations. Follow more values to increase accuracy.
              </Text>
            </Stack>

            <Stack
              direction={{ base: 'column', md: 'column' }}
              justify={'start'}
              align={'start'}
            >
              <Image src={'./thumb.svg'} boxSize={'48px'} />
              <Text variant={'whatSubHeader'}>
                Brand Ratings
              </Text>
              <Text variant={'whatSubText'}>
                Your personalized ratings indicate how aligned brands are with your unique beliefs. The higher your score, the closer you’re matched.
              </Text>
            </Stack>
          </Stack>

          <Stack
            justify={'center'}
            align={'center'}
            py={{ base: '25px', md: '0px' }}
            px={{ base: '0px', md: '15px' }}
          >
            <Image src={'./img/mobile1.webp'} maxH={'600px'} w={'600px'} objectFit='contain' />
          </Stack>

          <Stack direction={'column'} justify={'space-evenly'}>
            <Stack
              direction={{ base: 'column', md: 'column' }}
              justify={'start'}
              align={'start'}
            >
              <Image src={'./wallet.svg'} boxSize={'48px'} />
              <Text variant={'whatSubHeader'}>
                Take Action
              </Text>
              <Text variant={'whatSubText'}>
                Use your personalized ratings to vote with your wallet. Support brands that align with your values and avoid the ones that do not.
              </Text>
            </Stack>

            <Stack
              direction={{ base: 'column', md: 'column' }}
              justify={'start'}
              align={'start'}
            >
              <Image src={'./share.svg'} boxSize={'48px'} />
              <Text variant={'whatSubHeader'}>
                Grow Our Cause
              </Text>
              <Text variant={'whatSubText'}>
                Share our app with your friends, family, and colleagues! Help us grow the belief-driven buying movement and make an impact.
              </Text>
            </Stack>
          </Stack>


        </SimpleGrid>

      </SimpleGrid>
    </Box>
  )
}