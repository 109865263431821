import { extendTheme } from '@chakra-ui/react';

export const baseTheme = extendTheme({
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`,
  },
  style: {
    global: {
      body: {
        bg: 'gray.400',
        color: 'truvalGrey.400',
      },
    },
  },
  colors: {
    truvalGreen: {
      300: '#00ABB6',
      200: '#2DB9BE',
      100: '#50C8CD',
    },
    truvalGrey: {
      400: '#666666',
      300: '#7A7878',
      200: '#929292',
      100: '#9D9D9D',
    },
    truvalyouBlue: {
      400: '#010051',
      300: '#010051',
      200: '#010051',
      100: '#010051',
    },
  },
  components: {
    Text: {
      baseStyle: { fontWeight: '500' },
      variants: {
        introQuiz: {
          color: 'truvalGrey.400',
          fontSize: { base: 10, md: 100 },
        },
        introHeader: {
          fontWeight: '700',
          color: 'truvalyouBlue.300',
          fontSize: { base: '4xl', md: '7xl' },
        },
        introBody: {
          'font-family': 'var(--chakra-fonts-body)',
          color: 'truvalyouBlue.300',
          fontSize: { base: 'sm', md: 'md' },
        },
        modalContent: {
          color: 'truvalyouBlue.300',
          fontSize: { base: 'sm', md: 'md' },
        },
        modalNumber: {
          color: 'truvalyouBlue.300',
          fontWeight: '700',
          fontSize: { base: '3xl', md: '3xl' },
        },
        modalNumberText: {
          color: 'truvalyouBlue.300',
          fontSize: { base: 'xs', md: 'xs' },
        },
        beliefHeader: {
          fontWeight: '700',
          color: 'white',
          fontSize: 'xl',
          lineHeigh: '1.2',
          // paddingTop: { base: '5px', md: '30px' },
        },
        beliefBody: {
          color: 'white',
          fontSize: 'sm',
          textAlign: 'left',
          // paddingTop: '0px',
        },
        beliefSubtext: {
          color: 'white',
          fontSize: 'xs',
          textAlign: 'right',
        },
        whatHeader: {
          fontWeight: '700',
          color: 'truvalyouBlue.300',
          fontSize: { base: '3xl', md: '4xl' },
        },
        whatHeaderText: {
          color: 'truvalyouBlue.300',
          fontSize: { base: 'sm', md: 'md' },
        },
        whatSubHeader: {
          fontWeight: '700',
          color: 'truvalyouBlue.300',
          fontSize: { base: 'xl', md: 'xl' },
          // px: '15px',
          textAlign: 'start',
        },
        whatSubText: {
          color: 'truvalyouBlue.300',
          fontSize: { base: 'sm', md: 'sm' },
        },
        footerHeader: {
          color: 'truvalyouBlue.300',
          fontSize: 'sm',
          fontWeight: 'bold',
        },
        footerText: {
          color: 'truvalyouBlue.300',
          fontSize: { base: 'sm', md: 'sm' },
        },
        footerFollow: {
          color: 'truvalyouBlue.300',
          fontSize: 'md',
          fontWeight: 'bold',
        },
        downloadButton: {
          color: 'white',
          fontSize: '18px',
          // fontWeight: 'thin',
          // fontSize: { base: 'xs', md: 'xs' },
        }
      },
    },
    Heading: {
      variants: {
        white: {
          color: 'white',
        },
        grey: {
          color: 'truvalGrey.400',
        },
      },
    },
    Button: {
      variants: {
        info: {
          rounded: 100,
          variant: 'outline',
          border: '4px',
          color: 'white',
          h: '45px',
          w: '45px',
          _hover: { bg: 'white', color: 'truvalGreen.200' },
        },
      },
    },
  },
});

// Perlu dibuat component style apa ga? klo perlu apa aja yg perlu dibuat, soalnya banyak banget component yg terpisah dengan penulisan style yg bervariasi

// coba buat component 1 dlu untuk company card di quiz, klo bagus bisa diterapin ke wapp

// Jdy: Agree!  :D