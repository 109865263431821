import React from 'react'
import { Link, } from 'react-router-dom';

import {
  Box,
  Image,
} from '@chakra-ui/react';

export default function QuizHeader() {

  return (
    <Box
      display="flex"
      justifyContent={{ base: 'center', md: 'start' }}
      p={4}
    >
      <Link to='/' >
        <Image src="/truvalyou-logo.svg" alt="truvalyou" h={41} />
      </Link>

    </Box>
  )
}