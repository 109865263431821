import {
  Link,
  Stack,
  Box,
  Text,
} from '@chakra-ui/react';

import { FaApple } from 'react-icons/fa';

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

export default function AppStoreButton({ company }) {
  return (<>
    <Link
      href={'https://m.truvalyou.com/www-apple'}
      isExternal
      onClick={() => {
        // Send Analytics.
        mixpanel.init(MIXPANEL_ID);
        mixpanel.track('Button Pressed', {
          'Screen Name': 'App Store Button ',
          'Platform': 'WWW',
        });

      }}
    >
      <Box bg={'#010051'} color={'white'} h={'45px'} w={'155px'} display='flex' justifyContent={'center'} >
        <Stack direction={'row'} align={'center'} >
          <FaApple size={'22'} />
          <Text variant={'downloadButton'}>App Store</Text>
        </Stack>
      </Box>
    </Link>
  </>)
}
