import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import Home from './pages/Home';
import { QuizIntro } from './pages/QuizIntro';
import { CompanySelect } from './pages/CompanySelect';
import { CompanyAlign } from './pages/CompanyAlign';
import { ValuesSelect } from './pages/ValuesSelect';
import { CompanyScore } from './pages/CompanyScore';

import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/300.css';
import baseTheme from './theme';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Footer from './components/Footer';

import CookieConsent from "react-cookie-consent";
import ScrollToTop from './components/ScrollToTop';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-PS8W22J'
}

TagManager.initialize(tagManagerArgs);


function App() {
  return (
    <ChakraProvider theme={baseTheme}>
      <BrowserRouter>
        <ScrollToTop />
        <CookieConsent
          location="bottom"
          style={{ background: "#ECECEC", color: "black", zIndex: '1010' }}
          buttonStyle={{ color: "white", fontSize: "13px", background: "#50C8CD" }}
          acceptOnScroll={true}
          acceptOnScrollPercentage={50}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quizIntro" element={<QuizIntro />} />
          <Route path="/companySelect" element={<CompanySelect />} />
          <Route path="companyAlign/:id" element={<CompanyAlign />} />
          <Route path="valuesSelect/:id" element={<ValuesSelect />} />
          <Route path="companyScore/:id" element={<CompanyScore />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
