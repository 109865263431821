import React from 'react'
import {
  Box,
  Text,
  Stack,
  SimpleGrid,
  Link
} from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons'


export default function BeliefBuyingExplanation() {
  return (
    <Box bg={'truvalyouBlue.300'} py={{ base: '25px', md: '50px' }}>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={12}
        // py={{ base: '5px', md: '20px' }}
        px={{ base: '25px', md: '25px' }}
      >
        {/* Bold text description */}
        <Stack direction={'column'}>
          <Text variant={'beliefHeader'}>
            Belief-driven Buying
          </Text>
          <Stack direction={'row'} align={'baseline'}>
            <Text variant={'beliefBody'}>
              Did you know?  58% of consumers buy or advocate for brands based on their beliefs and values.&nbsp;
              <Link
                href={'https://www.edelman.com/news-awards/two-thirds-consumers-worldwide-now-buy-beliefs'}
                isExternal
              >(Edelman, 2022) <ExternalLinkIcon mx='2px' /></Link>
            </Text>
          </Stack>
        </Stack>

        <Stack direction={'column'}>
          <Text variant={'beliefHeader'}>
            Shop Smarter
          </Text>
          <Text variant={'beliefBody'}>
            Our app rates 2,000+ globally recognized brands based on your values. New brands are added every day!
          </Text>
        </Stack>

        <Stack direction={'column'}>
          <Text variant={'beliefHeader'}>
            Your Values
          </Text>
          <Text variant={'beliefBody'}>
            Follow the values you believe in, including Animal Rights, Sustainability, LGBTQ+, Human Rights, and many more!
          </Text>
        </Stack>

      </SimpleGrid>
    </Box>
  )
}