import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';

import { db } from '../firebase/config.js';

import { doc, getDoc } from 'firebase/firestore';

import {
  CompanyCard,
  QuizShareList,
  InfoButton,
  QuizHeader,
} from '../components';

import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Stack,
  Link as ChakraLink
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

import PlayStoreButton from '../components/PlayStoreButton';
import AppStoreButton from '../components/AppStoreButton';

export const CompanyScore = () => {
  useEffect(() => {
    // Send Analytics.
    mixpanel.init(MIXPANEL_ID);
    mixpanel.track('Page Viewed', {
      'Screen Name': 'Quiz Results',
      'Platform': 'WWW',
    });
  }, []);

  const { id } = useParams();
  const location = useLocation();
  const [userQuizDetails, setUserQuizDetails] = useState([]);
  const [loadingUserQuizDetails, setLoadingUserQuizDetails] = useState(true);

  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    return () => { setIsCancelled(true); }
  }, []);

  useEffect(() => {
    const getQuizData = async () => {
      const docQuiz = doc(db, `quiz-results`, id);

      getDoc(docQuiz).then((doc) => {
        if (!isCancelled) {
          setUserQuizDetails(doc.data());
          setLoadingUserQuizDetails(false);
        }
      });
    };
    getQuizData();
    try {
      getQuizData();
    } catch (e) {
      // console.log(e);
    }
  }, [id, isCancelled]);
  // console.log(userQuizDetails);

  const infoButtonMessage =
    'Your score is unique and calculated using the values you have selected in this quiz. You should support brands with scores closer to 100, and avoid  the ones with scores closer to 0. To receive personalized ratings for 2,000+ brands, please consider installing our mobile app for iOS or Android today.';
  // const scoreInfoMessage =
  //   'Your unique score for this company is calculated using the value groups you have followed in the app. Scores are calculated out of 100, with lower scores indicating the company is misaligned with your values.';

  // console.log(userQuizDetails);
  // console.log(`User thought: ${userQuizDetails.companyAlign} And Results were: ${userQuizDetails.aligned}`)

  return (
    <Box bg="truvalGreen.300">
      <QuizHeader />
      <Flex
        bg="truvalGreen.300"
        flexDirection="column"
        justifyContent="center"
        alignItems={'center'}
        textAlign={'center'}
        display={'flex'}
      >
        {loadingUserQuizDetails ? null : (
          <Heading
            // my={3}
            color="truvalyouBlue.300"
            fontSize={{ base: 'lg', md: 'xl' }}
            fontWeight={'bold'}
            w={{ base: '80%', md: '80%' }}
          >
            Based on your selected values, your score for&nbsp;
            {userQuizDetails.companyDetails.name} is {userQuizDetails.userScore}.
          </Heading>
        )}
        <Box justifyContent="center" display={'flex'} mt={'20px'}>
          {loadingUserQuizDetails ? null : (
            <CompanyCard company={userQuizDetails.companyDetails} score={userQuizDetails.userScore} />
          )}
        </Box>

        {loadingUserQuizDetails ? null : (
          <Box px={'15px'} py={'20px'}>
            {/* {(
              ((userQuizDetails.companyAlign === 0) && (userQuizDetails.aligned === 'unaligned'))
              || ((userQuizDetails.companyAlign === 50) && (userQuizDetails.aligned === 'neutral'))
              || ((userQuizDetails.companyAlign === 100) && (userQuizDetails.aligned === 'aligned'))
            ) ? (<Text align={'center'} fontSize="xl" color="truvalyouBlue.300">You guessed correctly!</Text>)
              : (<Text align={'center'} fontSize="xl" color="truvalyouBlue.300">You guessed incorrectly!</Text>)
            } */}
            <Text fontSize={{ base: 'md', md: 'lg' }} color="truvalyouBlue.300" px={'15px'} align={'start'}>
              {userQuizDetails.companyDetails.name} is {userQuizDetails.aligned} with your values, you&nbsp;
              {
                (userQuizDetails.userScore < 30) ?
                  'should stop shopping from them and find an alternative.'
                  : ((userQuizDetails.userScore >= 30) && (userQuizDetails.userScore < 70))
                    ? 'should be wary of shopping with them and may wish to seek better alternatives.'
                    : 'should continue shopping from them.'
              }
            </Text>
          </Box>
        )}

        <Box bg={'truvalGreen.300'} w={'100%'}>
          {loadingUserQuizDetails ? null : (
            <Box px={'10px'} py={'20px'} >
              <Text fontSize={{ base: 'xl', md: '2xl' }} color={'truvalyouBlue.300'} fontWeight={'bold'}>
                Get the App!
              </Text>
              <Stack direction={'row'} p={'10px'} align={'center'} justify={'center'} >
                <AppStoreButton />
                <PlayStoreButton />
              </Stack>
              <Text fontSize={{ base: 'sm', md: 'lg' }} color={'truvalyouBlue.300'}>
                Download our app to see your personalized rating for {userQuizDetails.companyDetails.name} and 2,000+ other brands based on your unique values!
              </Text>
            </Box>
          )}
        </Box>

        <Box color={'truvalyouBlue.300'} w={'100%'}>
          {loadingUserQuizDetails ? null : (
            <QuizShareList
              quiz={userQuizDetails}
              message="Share your results!"
            />
          )}


          <Box p={'20px'} bg={'truvalGreen.200'}>
            <Link to={location.state ? '/companySelect' : '/quizIntro'}>
              <Button
                color={'white'}
                bg={'truvalyouBlue.300'}
              // my={6}
              >
                Try Again
              </Button>
            </Link>
            <Text align={'center'} color={'truvalyouBlue.300'} paddingTop={'10px'}>
              Questions or feedback?&nbsp;
              <ChakraLink
                href={'https://g1572lnltss.typeform.com/to/uAPpho01'}
                isExternal
              >
                Contact us here!<ExternalLinkIcon mx='2px' />
              </ChakraLink>
            </Text>
          </Box>
          <Box px={'0px'}>
            {/* <Text my={1}>
              Join our app to receive personalized scores for 2,000+ brands based
              on your values. Support companies that align with your beliefs, and
              avoid the ones that don't.
            </Text>
            <Text my={2}>Download the app today for iOS or Android below,</Text>
            <Text>It's completely free!</Text> */}
            {/* <Stack direction={'row'} p={'10px'} align={'center'} justify={'center'} >
              <AppStoreButton />
              <PlayStoreButton />
            </Stack> */}
          </Box>
        </Box>
      </Flex>
      {/* <Box pos="fixed" bottom="5" right="5">
        <InfoButton message={infoButtonMessage} />
      </Box> */}
    </Box>
  );
};
