import React, { useEffect, useState } from 'react';

import { db } from '../firebase/config.js';

import { doc, getDoc } from 'firebase/firestore';

import {
  Box,
  Grid,
  Text,
  Image,
  Input,
  GridItem,
  useCheckbox,
} from '@chakra-ui/react';

import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from 'react-icons/md';
// Imports for Google Storage files
import { useStorageDownloadUrl } from '../hooks/useStorageDownloadUrl';

export const ValuesCard = (props) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const [isCancelled, setIsCancelled] = useState(false);
  useEffect(() => {
    return () => { setIsCancelled(true); }
  }, []);

  const [valueDetails, setValueDetails] = useState({ name: '' });

  useEffect(() => {
    try {
      const docValue = doc(db, 'revolutions', props.value);

      getDoc(docValue).then((doc) => {
        if (!isCancelled) {
          setValueDetails(doc.data());
        }
      });
    } catch (e) {
      // console.log(e);
    }
  }, [props.value, isCancelled]);

  const imageUrl = useStorageDownloadUrl(valueDetails.thumbUrl, valueDetails.imageName);
  // console.log(input.checked);
  return (
    <Box as="label">
      <Input {...input} />
      <Box
        {...checkbox}
        bg="white"
        w="100%"
        shadow="md"
        rounded={2}
        borderWidth="2px"
        borderColor={'white'}
        p={'1px'}
        h="4rem"
        color={'truvalGrey.400'}
        _checked={{ bg: 'truvalGreen.300', color: 'white' }}
      >
        <Grid
          templateRows="repeat(3, 1fr)"
          templateColumns="repeat(5, 1fr)"
          w="100%"
          h="100%"
        >
          <GridItem
            rowSpan={3}
            colSpan={1}
            w={100}
            my={'1px'}
            paddingRight={'3px'}
            display="flex"
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box
              bgSize="cover"
              w="100%"
              h="100%"
              justifyContent={'center'}
              display="flex"
            >
              <Image
                w="90%"
                h="100%"
                objectFit="scale-down"
                src={
                  !imageUrl.imageUrl === null
                    ? '../loading-image.png'
                    : imageUrl.imageUrl
                }
              />
            </Box>
          </GridItem>
          <GridItem
            colSpan={4}
            rowSpan={2}
            my={2}
            display="flex"
            alignItems={'center'}
            h="100%"
          >
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              color={"truvalGrey.400"}
              fontWeight={'bold'}
              justifyContent={'start'}
              textAlign="start"
              alignItems={'start'}

            >
              {valueDetails.name}
            </Text>
          </GridItem>
          <GridItem
            colSpan={4}
            rowSpan={1}

            display="flex"
            textAlign={'center'}
            alignItems="center"
            justifyContent={'end'}
            gap={2}
          >
            {input.checked === true ? (
              <MdOutlineCheckBox size={24} />
            ) : (
              <MdOutlineCheckBoxOutlineBlank size={24} />
            )}
            {/* <IoPeopleOutline />
            <Text> {valueDetails.follower_count}</Text> */}
          </GridItem>
        </Grid>
      </Box>
    </Box >
  );
};
