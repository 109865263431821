import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import {
  Box,
  Text,
  Stack,
  Image,
  Flex,
} from '@chakra-ui/react';

import { IoPlayCircleOutline } from "react-icons/io5";

import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';


export default function BeliefBuyingExplanation() {
  const [isOpen, setOpen] = useState(false)

  return (
    <Box bg={'#00ABB6'} >
      <Flex>
        <Flex
          justify={{ base: 'start', md: 'start' }}
          display={{ base: 'flex', md: 'none' }}
        >
          <Image
            src='/truvalyou-logo.svg'
            alt='truvalyou' w={'200px'}
            paddingLeft={'15px'}
            paddingTop={'10px'}
          />
        </Flex>

        <Stack
          flex={{ base: 1, md: 1 }}
          justify={'flex-end'}
          direction={'row'}
        >
          {/* <Container bg={'#010051'} py={'2'}> */}
          <Link to={'/quizIntro'}>
            <Stack direction={'row'} bg={'#010051'} align={'center'} py={'3'} px={'3'} as="button">
              <IoPlayCircleOutline size={'28'} color={'white'} />
              <Text fontSize={{ base: '14px', md: '22px' }} color={'white'} fontWeight={'700'}>GET STARTED</Text>
            </Stack>
          </Link>
          {/* <React.Fragment>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="5FPcu53FNH0" onClose={() => setOpen(false)} />
            <button
              className="btn-primary"
              onClick={() => {
                setOpen(true);

                // Send Analytics.
                mixpanel.init(MIXPANEL_ID);
                mixpanel.track('Button Pressed', {
                  'Screen Name': 'Get Started Video ',
                  'Platform': 'WWW',
                });

              }}
            >
              <Stack direction={'row'} bg={'#010051'} align={'center'} py={'3'} px={'3'}>
                <IoPlayCircleOutline size={'28'} color={'white'} />
                <Text fontSize={{ base: '14px', md: '22px' }} color={'white'} fontWeight={'700'}>GET STARTED</Text>
              </Stack>
            </button>
          </React.Fragment> */}
          {/* </Container> */}

        </Stack>
      </Flex>
    </Box>
  )
}