import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Heading,
  InputGroup,
  Input,
  InputRightElement,
  FormControl,
  Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CompanyCard, InfoButton, QuizHeader } from '../components';
import { useEsQuizCompaniesSearch } from '../hooks/useEsQuizCompaniesSearch';

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

export const CompanySelect = () => {
  useEffect(() => {
    // Send Analytics.
    mixpanel.init(MIXPANEL_ID);
    mixpanel.track('Page Viewed', {
      'Screen Name': 'Quiz Company Select',
      'Platform': 'WWW',
    });
  }, []);

  const navigate = useNavigate();

  // Form stuffs
  const {
    register,
    watch,
  } = useForm();
  // const [search, setSearch] = useState('');

  const { esQuizCompanySearch, quizCompaniesList } = useEsQuizCompaniesSearch();

  const searchQuery = watch('search');

  useEffect(() => {
    // Get Companies
    try {
      esQuizCompanySearch(searchQuery);
    } catch (e) {
      // console.log(e);
    }
  }, [searchQuery]);

  const infoButtonMessage =
    "We've limited your selection to companies that have been rated as supported or avoided by at least 5 values in the app. To view the full list of 2,000+ companies, please consider installing our mobile app for iOS or Android today.";

  return (
    <Box bg="truvalGreen.300" paddingBottom={{ base: '200px', md: '800px' }}>
      <QuizHeader />
      <Flex
        bg="truvalGreen.300"
        flexDirection="column"
        justifyContent="center"
        alignItems={'center'}
        textAlign={'center'}
        display={'flex'}
      >
        <Heading
          my={3}
          color="truvalyouBlue.300"
          fontSize={{ base: 'md', md: 'xl' }}
          fontWeight={'bold'}
          w={{ base: '90%', md: '80%' }}
        >
          Search for or select a brand you currently shop at
        </Heading>
        <FormControl
          isRequired
          justifyContent="center"
          alignContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          display={'flex'}
          w={{ base: '90%', md: '100%' }}
          my={2}
        >
          {/* <FormLabel color={'#666666'} requiredIndicator='' htmlFor='search'>Search Term</FormLabel> */}
          <InputGroup maxW={450} justifyContent="center" alignItems="center">
            <Input
              id="search"
              type="text"
              // onChange={(event) => setSearch(event.currentTarget.value)}
              bg="white"
              {...register('search', {
                required: true,
                minLength: 2,
                maxLength: 16,
                // pattern: /^[a-zA-Z0-9]*$/
              })}
            />
            <InputRightElement w="4.5rem">
              <Button type="submit">
                {/* isDisabled={!isSubmitting} */}
                Search
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Box
          w="100%"
          alignContent="center"
          alignItems={['center', 'center', 'flex-start']}
          justifyContent="center"
          alignSelf="center"
          textAlign={'center'}
          display={'flex'}
          my={2}
        >
          <SimpleGrid
            columns={{ base: 2, md: 3 }}
            spacingX={{ base: 2, md: 100 }}
            spacingY={{ base: 2, md: 30 }}
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            display={'grid'}
            w={{ base: '95%', md: '100vh', lg: '70vh' }}
          >
            {quizCompaniesList &&
              quizCompaniesList.map((value, key) => {
                // console.log(key);

                // Only show 6 results to avoid doom scrolling
                if (key < 6) {
                  return (
                    <Box
                      key={value.cid}
                      justifyContent="center"
                      display={'flex'}
                      px={{ base: 5 }}
                    >
                      <NavLink
                        exact="true"
                        to={`/valuesSelect/${value.cid}`}
                        className="navlink"
                      >
                        <CompanyCard company={value} />
                      </NavLink>
                    </Box>
                  );
                }
              })}
          </SimpleGrid>
        </Box>
        <Button my={5} onClick={() => navigate(-1)} bg={'truvalyouBlue.300'} color={'white'}>
          Back
        </Button>
        <Text color={'truvalyouBlue.300'} mt={1} fontWeight={'bold'}>
          Step 1/3
        </Text>
      </Flex>
      {/* 
      <Box pos="fixed" bottom="5" right="5">
        <InfoButton message={infoButtonMessage} />
      </Box> */}
    </Box>
  );
};

export default CompanySelect;
