import React from 'react';
import {
  Box,
  IconButton,
  useBreakpointValue,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';

// Here we have used react-icons package for the icons
import { VscArrowLeft, VscArrowRight } from 'react-icons/vsc';

// And react-slick as our Carousel Lib
import Slider from 'react-slick';

// Settings for the slider
const settings = {
  // dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function QuotesCarousel() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const side = useBreakpointValue({ base: '10px', md: '20px' });
  const top = useBreakpointValue({ base: '200px', md: '150px' });

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      name: 'Jesse Young',
      title: 'Co-Founder & CEO',
      quote:
        "\"I couldn’t find a way to see if the products I bought and the services I subscribed to aligned with my values. I started truvalyou to build a community around making this happen.\"",
      image:
        './img/profile_jesse.webp',
    },
    {
      name: 'Matthew Chadderdon',
      title: 'Co-Founder & Head of Marketing',
      quote:
        "\"Living a life harmonious with your values is difficult, but it does not have to be. Jesse and I want to make it easier than ever to support brands you align with, and avoid the rest. I hope you'll join us on our journey!\"",
      image:
        './img/profile_matt.webp',
    },
  ];

  return (
    <Box
      bg={'truvalyouBlue.300'}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        color={'white'}
        variant="ghost"
        colorScheme='truvalyouBlue.300'
        position="absolute"
        left={side}
        paddingTop={top}
        zIndex={2}
        onClick={() => slider?.slickPrev()}>
        <VscArrowLeft size="48px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        color={'white'}
        variant="ghost"
        colorScheme='truvalyouBlue.300'
        position="absolute"
        right={side}
        paddingTop={top}
        zIndex={2}
        onClick={() => slider?.slickNext()}>
        <VscArrowRight size="48px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              py={{ base: '15px', md: '25px' }}
              px={{ base: '15px', md: '55px' }}
              align={'center'}
            >
              <Image
                src={card.image}
                boxSize={{ base: '250px', md: '250px' }}
                align={{ base: 'center', md: 'center' }}
              />
              <Box w={'25px'} display={{ base: 'none', md: 'block' }} />
              <Stack
                spacing={6}
                direction={'column'}
              >
                <Image
                  src={'./img/quotemark.svg'}
                  boxSize={{ base: '25px', md: '25px' }}
                />
                <Text fontSize={{ base: 'md', lg: 'md' }} color={'white'}>
                  {card.quote}
                </Text>
                <Stack direction={'column'}>
                  <Text fontSize={{ base: 'md', lg: 'md' }} color={'truvalGreen.300'} fontWeight={'bold'}>
                    {card.name}
                  </Text>
                  <Text fontSize={{ base: 'md', md: 'md' }} color={'truvalGreen.300'}>
                    {card.title}
                  </Text>
                </Stack>

              </Stack>
            </Stack>
          </Box>
        ))
        }
      </Slider>
    </Box>
  );
}