import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Stack,
  Image,
  Button,
  List,
  ListItem,
  SimpleGrid
} from '@chakra-ui/react';

import { IoSearchSharp } from 'react-icons/io5'

import { functions } from '../firebase/config.js';
import { httpsCallable } from 'firebase/functions';

import { FormControl, FormErrorMessage, InputGroup, Input, InputRightElement, } from '@chakra-ui/react'
import { useForm } from "react-hook-form";

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../firebase/config';

// Imports for Google Storage files
import CompanyRow from './CompanyRow';
import GroupRow from './GroupRow';
import PlayStoreButton from './PlayStoreButton';
import AppStoreButton from './AppStoreButton';
// import CompanyModal from './CompanyModal';
// import GroupRow from './GroupRow';

export default function IntroHeader() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();


  useEffect(() => {
    // Send Analytics.
    mixpanel.init(MIXPANEL_ID);
    mixpanel.track('Page Viewed', {
      'Screen Name': 'Landing Screen ',
      'Platform': 'WWW',
    });

  }, []);

  const [companies, setCompanyList] = useState([]);
  const [groups, setGroupList] = useState([]);
  const searchQuery = watch('search');

  const onSubmit = searchQuery => {
    // console.log(searchQuery);

    // Get Companies
    try {
      let getCompanySearch = httpsCallable(functions, 'searchElasticCompany')

      // console.log(usernameTaken);
      getCompanySearch({ searchQuery: searchQuery.search }).then((results) => {
        // console.log(results.data.hits.hits);

        let docs = results.data.hits.hits;
        setCompanyList(docs);

        mixpanel.init(MIXPANEL_ID);
        mixpanel.track('Search', {
          'Type': 'Company',
          'Search Content': searchQuery,
          'Result Count': docs.length,
          'Origin': 'Landing Screen',
          'Platform': 'WWW',
        })
      })
    } catch (e) {
      console.log(e);
    }

    // Get Groups
    try {
      let getGroupSearch = httpsCallable(functions, 'searchElasticGroup ')

      // console.log(usernameTaken);
      getGroupSearch({ searchQuery: searchQuery.search }).then((results) => {

        let docs = 0;
        if (results.data.hits !== undefined) {
          // console.log(results.data.hits.hits);

          docs = results.data.hits.hits;
          setGroupList(docs);
        }

        mixpanel.init(MIXPANEL_ID);
        mixpanel.track('Search', {
          'Type': 'Group',
          'Search Content': searchQuery,
          'Result Count': docs.length,
          'Origin': 'Landing Screen',
          'Platform': 'WWW',
        })
      })
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    // Get Companies
    try {
      let getCompanySearch = httpsCallable(functions, 'searchElasticCompany')

      // console.log(usernameTaken);
      getCompanySearch({ searchQuery: searchQuery }).then((results) => {
        if (results.data.hits !== undefined) {
          // console.log(results.data.hits.hits);

          let docs = results.data.hits.hits;
          setCompanyList(docs);

          mixpanel.init(MIXPANEL_ID);
          mixpanel.track('Search', {
            'Type': 'Company',
            'Search Content': searchQuery,
            'Result Count': docs.length,
            'Origin': 'Landing Screen',
            'Platform': 'WWW',
          })
        }
      })
    } catch (e) {
      console.log(e);
    }

    // Get Groups
    try {
      let getGroupSearch = httpsCallable(functions, 'searchElasticGroup ')

      // console.log(usernameTaken);
      getGroupSearch({ searchQuery: searchQuery }).then((results) => {
        // console.log(results.data.hits);
        if (results.data.hits !== undefined) {
          // console.log(results.data.hits.hits);
          let docs = results.data.hits.hits;
          setGroupList(docs);

          mixpanel.init(MIXPANEL_ID);
          mixpanel.track('Search', {
            'Type': 'Group',
            'Search Content': searchQuery,
            'Result Count': docs.length,
            'Origin': 'Landing Screen',
            'Platform': 'WWW',
          })
        }
      })
    } catch (e) {
      console.log(e);
    }
  }, [searchQuery])

  return (
    <Box bg={'#00ABB6'} paddingBottom={{ base: '5px', md: '15px' }}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} py={1}>

        {/* Left/top here (text, etc) */}
        <Stack
          paddingLeft={{ base: '10px', md: '60px' }}
          paddingRight={{ base: '10px', md: '0px' }}
          paddingTop={{ base: '5px', md: '20px' }}
        // px={{ base: 10, md: 20 }}
        >
          <Stack
            display={{ base: 'none', md: 'block' }}
            align={{ base: 'center', md: 'start' }}
            paddingTop={{ base: '10px', md: '0px' }}
          >
            <Image src='/truvalyou-logo.svg' alt='truvalyou' height={{ base: '35px', md: '50px' }} />
          </Stack>
          <Text
            variant={'introHeader'}
            lineHeight={1.2}
            textAlign={{ base: 'center', md: 'left' }}
          // paddingTop={'10px'}
          >
            {/* Support or avoid brands using your values */}
            Support brands that share your values
          </Text>
          <Text
            variant={'introBody'}
            textAlign={{ base: 'center', md: 'left' }}
            paddingTop={'10px'}
          >
            Easily search for, discover, and shop from brands that align with your unique beliefs.
          </Text>

          {/* Search Bar will go here. */}
          <Box py={{ base: '2', md: '2' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isRequired isInvalid={errors.search} align={'center'}>
                {/* <FormLabel color={'#666666'} requiredIndicator='' htmlFor='search'>Search Term</FormLabel> */}
                <InputGroup align={'center'}>
                  <Input
                    focusBorderColor={'#666666'}
                    placeholder='Search for a brand or value...'
                    id='search'
                    type='text'
                    // onChange={event => setSearch(event.currentTarget.value)}
                    bg='white'
                    {...register('search', {
                      required: true,
                      minLength: 2,
                      maxLength: 16,
                      // pattern: /^[a-zA-Z0-9]*$/
                    })}
                  />
                  <InputRightElement w='3rem'>
                    <Button type='submit' >
                      <IoSearchSharp size='25' />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {(companies?.length > 0 || groups?.length > 0) &&
                  <List
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                    // boxShadow="6px 5px 8px rgba(0,50,30,0.02)"
                    mt={2}
                    bg={'white'}
                    maxW={{ base: '425', md: '625' }}
                  >
                    {companies?.length > 0 &&
                      companies.map((company, index) => {
                        // console.log(company._source);
                        if (index === 0) {
                          return (
                            <>
                              <ListItem>- Brands -</ListItem>
                              <ListItem
                                key={company._source.cid}
                                _hover={{ bg: 'gray.100' }}
                                my={1}
                                p={2}
                                cursor="pointer"
                              >
                                <CompanyRow company={company._source} />
                              </ListItem>
                            </>
                          )
                        }
                        else if (index < 3) {
                          return (
                            <ListItem
                              key={company._source.cid}
                              _hover={{ bg: 'gray.100' }}
                              my={1}
                              p={2}
                              cursor="pointer"
                            >
                              <CompanyRow company={company._source} />
                            </ListItem>
                          );
                        }
                        else { return (<></>) }
                      })
                    }
                    {groups?.length > 0 &&
                      groups.map((group, index) => {
                        // console.log(index);
                        if (index === 0) {
                          return (
                            <>
                              <ListItem>- Values -</ListItem>
                              <ListItem
                                key={group._source.rid}
                                _hover={{ bg: 'gray.100' }}
                                my={1}
                                p={2}
                                cursor="pointer"
                              >
                                <GroupRow group={group._source} />
                              </ListItem>
                            </>)

                        }
                        else if (index < 3) {
                          return (
                            <ListItem
                              key={group._source.rid}
                              _hover={{ bg: 'gray.100' }}
                              my={1}
                              p={2}
                              cursor="pointer"
                            >
                              <GroupRow group={group._source} />
                            </ListItem>
                          );
                        }
                        else { return (<></>) }
                      })
                    }
                  </List>
                }
                {errors.search?.type === 'minLength' &&
                  <FormErrorMessage color={'white'} fontWeight={'semibold'}>
                    Search must be at least 2 characters
                  </FormErrorMessage>
                }
              </FormControl>
            </form>
          </Box>

          {/* Download the app */}
          <Stack justify={'center'} align={'center'} direction={'row'} paddingTop={'15px'}>
            <AppStoreButton />
            <PlayStoreButton />
          </Stack>

        </Stack>

        {/* Right/bottom here (image) */}
        <Stack
          // px={{ base: 10, md: 10 }}
          py={{ base: '10px', md: '20px' }}
          paddingLeft={{ base: '10px', md: '0px' }}
          paddingRight={{ base: '10px', md: '60px' }}
          align={'center'}
        >
          <Image
            // src='/intro-mobile.png'
            src={'./img/www-intro-image-4.webp'}
            boxSize={{ base: '375', md: '650px' }}
            objectFit='contain'
          />
        </Stack>
      </SimpleGrid>
      {/* </Stack> */}
    </Box>
  )
}