import React from 'react';
import BeliefBuyingExplanation from '../components/BeliefBuyingExplanation';
import IntroHeader from '../components/IntroHeader';
import ModeratorRequest from '../components/ModeratorRequest';
import OnAMission from '../components/OnAMission';
import QuotesCarousel from '../components/QuotesCarousel';
import TopBar from '../components/TopBar';
import WhatWeDoForYou from '../components/WhatWeDoForYou';
import TakeTheQuiz from '../components/TakeTheQuiz';

export default function Home() {
  return (
    <>
      {/* <Navbar /> */}
      <TopBar />
      <IntroHeader />
      <TakeTheQuiz />
      <BeliefBuyingExplanation />
      <WhatWeDoForYou />
      {/* <HowItWorks /> */}
      {/* <Stats /> */}
      <QuotesCarousel />
      {/* <AboutUs /> */}
      <OnAMission />
      <ModeratorRequest />
      {/* <Footer /> */}
    </>
  );
}
